import React, { useState } from 'react';
import { Container, TextField, Button, MenuItem, Typography, Grid, Box, Card, CardMedia, CardContent } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../Components/Header';
import Footer from './Footer';
import sunset from '../images/begin.jpeg'; 
import abstract from '../images/inter2.jpeg'; 
import mountain from '../images/expert.jpeg';
import slidedata1 from '../images/slidedata1.jpeg'
import slidedata2 from '../images/slidedata2.jpeg'
import slidedata3 from '../images/slidedata3.jpeg'
import slidedata4 from '../images/slidedata4.jpeg'
import slidedata5 from '../images/slidedata5.jpeg'
import slidedata6 from '../images/slidedata9.jpeg'
import SimpleImageSlider from "react-simple-image-slider";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HomeIcon from '@mui/icons-material/Home';
import './Arts.css'
const theme = createTheme();

const ArtsPage = () => {
  const [formData, setFormData] = useState({
    studentName: '',
    parentName: '',
    email: '',
    phone: '',
    dob: '',
    startDate: '',
    schedule: '',
    teacher: '',
    time: '', // Initialize with an empty string
  });

  const [errors, setErrors] = useState({});

  const timezones = [
    'PST',
    'EST',
    'CST',
    'IST',
    'UTC',
  ];

  const programs = [
    'Sketching',
    'Watercolor Painting',
    'Oil Painting',
    'Digital Art',
  ];

  const schedules = [
    'Weekly',
    'Monthly',
  ];

  const teachers = [
    'Mr. Smith',
    'Ms. Johnson',
    'Mrs. Brown',
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); 
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    const requiredFields = {
      studentName: 'Student name is required',
      parentName: 'Parent name is required',
      email: 'Email is required',
      phone: 'Phone number is required',
      dob: 'Date of birth is required',
      startDate: 'Start date is required',
      program: 'Program selection is required',
      schedule: 'Schedule is required',
      teacher: 'Teacher selection is required',
      time: 'Time is required'
    };
  errors = {}; 
   isValid = true;

Object.keys(requiredFields).forEach(field => {
  if (!formData[field]) {
    errors[field] = requiredFields[field];
    isValid = false;
  }
});

    

    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }

    const today = new Date().toISOString().split('T')[0];
    if (formData.startDate && formData.startDate < today) {
      errors.startDate = 'Start date cannot be in the past';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validateForm()) {
  //     try {
  //       const response = await fetch('http://34.201.62.141:5000/api/student-form', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(formData),
  //       });

  //       const result = await response.json();

  //       if (response.ok) {
  //         console.log(result.message);
  //         alert('Thanks for registering with us! We’ll get back to you shortly.');
  //       } else {
  //         console.error(result.message);
  //         alert('Error submitting form');
  //       }
  //     } catch (error) {
  //       console.error('Error submitting form:', error);
  //       alert('Error submitting form');
  //     }
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      // Combine the time and timezone
      const combinedTime = `${formData.time} ${formData.timezone}`;
  
      const payload = {
        ...formData,
        time: combinedTime, // Combine time and timezone in the payload
        timezone: undefined, // Optionally, you can remove the separate timezone key
      };
  
      try {
        const response = await fetch('http://34.201.62.141:5000/api/student-form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        const result = await response.json();
  
        if (response.ok) {
          console.log(result.message);
          alert('Thanks for registering with us! We’ll get back to you shortly.');
        } else {
          console.error(result.message);
          alert('Error submitting form');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Error submitting form');
      }
    }
  };

  return (
    <><div>
      <Header />
      <section className="arts-header">
        <div className="header-content">
          <h1>Explore the World of Arts</h1>
          <p>Unleash your child's creativity! Register for our exciting kids' art classes.</p>
          <a href="#register" className="btn-cta">Register Now</a>
        </div>
      </section>

      <section className="art-categories" >
      <Box my={5}>
  <Typography
    variant="h5"
    gutterBottom
    className="header"
    style={{ color: '#088395', fontWeight: 'bold' }}
  >
    Art Gallery
  </Typography>
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={4}>
      <Card className="card-animation">
        <CardMedia
          component="img"
          alt="Beginners"
          image={sunset}
          title="Beginners"
          style={{ height: 'auto', maxHeight: '300px', objectFit: 'cover' }} 
        />
        <CardContent style={{ height: 'auto' }}> 
          <Typography variant="h6" style={{ color: '#071952', fontSize: '1.2rem' }}>
            <b>Beginners</b>
          </Typography>
          <Typography variant="body2" style={{ fontSize: '0.9rem' }}>
            Discover the basics of art with simple drawing techniques, fundamental color theory, and an introduction to various art tools. Learn to balance compositions, practice shading, and explore different art styles to build a solid foundation.
          </Typography>
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} sm={6} md={4}>
      <Card className="card-animation">
        <CardMedia
          component="img"
          alt="Intermediate"
          image={abstract}
          title="Intermediate"
          style={{ height: 'auto', maxHeight: '300px', objectFit: 'cover' }} 
        />
        <CardContent style={{ height: 'auto' }}> 
          <Typography variant="h6" style={{ color: '#071952', fontSize: '1.2rem' }}>
            <b>Intermediate</b>
          </Typography>
          <Typography variant="body2" style={{ fontSize: '0.9rem' }}>
            Enhance your skills with advanced drawing techniques, in-depth color theory, and a variety of art media. Focus on complex compositions, master shading, and develop your unique artistic style while gaining a deeper understanding of art history.
          </Typography>
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} sm={6} md={4}>
      <Card className="card-animation">
        <CardMedia
          component="img"
          alt="Experts"
          image={mountain}
          title="Experts"
          style={{ height: 'auto', maxHeight: '300px', objectFit: 'cover' }} 
        />
        <CardContent style={{ height: 'auto' }}> 
          <Typography variant="h6" style={{ color: '#071952', fontSize: '1.2rem' }}>
            <b>Experts</b>
          </Typography>
          <Typography variant="body2" style={{ fontSize: '0.9rem' }}>
            Master drawing, painting, and color application. Explore advanced techniques, create innovative compositions, and refine your personal style. Engage in contemporary art discussions and contribute to the art world with impactful projects
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
</Box>

<div className="container" id='register'>
          {/* <Container maxWidth="md"> */}
            {/* <Box my={5}> */}
              <Typography variant="h4" gutterBottom className="header" style={{ color: '#088395', fontWeight: 'bold' }}>
                Arts Drawing Program Registration
              </Typography>
              <Typography variant="body1" gutterBottom>
                Join our arts drawing program to unleash your creativity. We offer a range of programs including sketching, watercolor painting, oil painting, and digital art.<br/> Our experienced teachers are dedicated to providing personalized instruction to help you achieve your artistic goals.
              </Typography>
              {/* <Typography variant="body1" gutterBottom>
                Explore some of the amazing artwork created by our students and get inspired to create your own masterpieces!
              </Typography> */}
              <Typography variant="h6" gutterBottom style={{ color: '#ff5722', fontWeight: 'bold', marginTop: '24px' }}>
                Special Offer first Art Session Free!
              </Typography>
              <Typography variant="body1" style={{marginLeft:'42px'}}gutterBottom>
                Register now and enjoy your first session at no cost. Don’t miss this opportunity to start your creative journey with us!
              </Typography>
              <div  className="arts-form-container">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Student's Name"
                    name="studentName"
                    value={formData.studentName}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.studentName)}
                    helperText={errors.studentName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Parent's Name"
                    name="parentName"
                    value={formData.parentName}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.parentName)}
                    helperText={errors.parentName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    type="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.phone)}
                    helperText={errors.phone}
                    type="tel"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Date of Birth"
                    name="dob"
                    value={formData.dob}
                    onChange={(e) => handleDateChange('dob', e.target.value)}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.dob)}
                    helperText={errors.dob}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={(e) => handleDateChange('startDate', e.target.value)}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.startDate)}
                    helperText={errors.startDate}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Program"
                    name="program"
                    value={formData.program}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.program)}
                    helperText={errors.program}
                  >
                    {programs.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Schedule"
                    name="schedule"
                    value={formData.schedule}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.schedule)}
                    helperText={errors.schedule}
                  >
                    {schedules.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Teacher"
                    name="teacher"
                    value={formData.teacher}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.teacher)}
                    helperText={errors.teacher}
                  >
                    {teachers.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={10} sm={6}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          label="Preferred Time"
          variant="outlined"
          size="small"
          error={Boolean(errors.time)}
          helperText={errors.time}
          required
          type="time"
          name="time"
          value={formData.time}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: { step: 300 }, // 5 min
          }}
          style={{ marginRight: '8px', flex: 1 }} // Use flex to expand width
        />
        <TextField
          select
          label="Timezone"
          name="timezone"
          value={formData.timezone}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          error={Boolean(errors.timezone)}
          InputLabelProps={{ shrink: true }}
          style={{ width: '120px' }} // Set width for timezone select
        >
          {timezones.map((tz) => (
            <MenuItem key={tz} value={tz}>
              {tz}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Grid>
                  <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: '20px' }}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
              </div>
            {/* </Box> */}
            {/* </Container> */}
            </div>
            <section className="art-gallery">
      <Box my={5} className="links-container">
              <Typography variant="h5" gutterBottom className="header" style={{color:'#088395',fontWeight:'bold'}}>
                Inspirational Art Quotes and Drawing Links
              </Typography>
              <div className="quotes-container">
          <blockquote style={{backgroundColor:'antiquewhite'}}>
            "Art enables us to find ourselves and lose ourselves at the same time." — Thomas Merton
          </blockquote>
          <blockquote style={{backgroundColor:'antiquewhite'}}>
            "The purpose of art is washing the dust of daily life off our souls." — Pablo Picasso
          </blockquote>
          <blockquote style={{backgroundColor:'antiquewhite'}}>
            "Creativity takes courage." — Henri Matisse
          </blockquote>
        </div>
              <ul className="links-list">
                <li><a href="https://www.google.com/search?q=sketching+art&tbm=isch" target="_blank" rel="noopener noreferrer">Sketching Art</a></li>
                <li><a href="https://www.google.com/search?q=watercolor+painting&tbm=isch" target="_blank" rel="noopener noreferrer">Watercolor Painting</a></li>
                <li><a href="https://www.google.com/search?q=oil+painting&tbm=isch" target="_blank" rel="noopener noreferrer">Oil Painting</a></li>
                <li><a href="https://www.google.com/search?q=digital+art&tbm=isch" target="_blank" rel="noopener noreferrer">Digital Art</a></li>
              </ul>
            </Box>
      </section>
</section>
</div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className='contact-info'> 
                
                {/* Contact Us Section */}
                <div className='contact-us-data'> {/* Add marginRight to shift left */}
                    <Box
                        sx={{
                            padding: 2,
                            backgroundColor: 'aliceblue',
                            height: 'auto',
                        }}
                    >
                        <Typography variant="h6" gutterBottom style={{ color: '#088395', fontWeight: 'bold' }}>
                            Contact Us
                        </Typography>
                        <Typography variant="body1" component="div">
                            <h5>Have any questions? Feel free to Contact us at Anytime.</h5>
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}>
                            <WhatsAppIcon style={{ marginLeft: '10px', color: '#039dfc' }} />
                            <span style={{ color: '#039dfc', fontWeight: 'bold', marginLeft: '10px' }}>
                                Contact Number: <a href="https://wa.me/918870944351" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#039dfc' }}>+91-8870944351</a>
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px', marginTop: '30px' }}>
                            <HomeIcon style={{ marginLeft: '10px', color: '#039dfc' }} />
                            <span style={{ color: '#039dfc', fontWeight: '400', marginLeft: '10px' }}>
                                US: <a href="https://wa.me/16468649094" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#039dfc' }}>+1-646-864-9094</a>
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px', marginTop: '10px' }}>
                            <HomeIcon style={{ marginLeft: '10px', color: '#039dfc' }} />
                            <span style={{ color: '#039dfc', fontWeight: '400', marginLeft: '10px' }}>
                                Chennai: <a href="https://wa.me/918870944351" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#039dfc' }}>+91-8870944351</a>
                            </span>
                        </div>
                        <Button
                            variant="contained"
                            color="success"
                            href="https://wa.me/918870944351"
                            target="_blank"
                            rel="noopener noreferrer"
                            fullWidth
                            style={{ marginTop: '70px', backgroundColor: '#039dfc' }}
                        >
                            Chat on WhatsApp
                        </Button>
                    </Box>
                </div>

                {/* Art Section */}
                <div className='arts-data'>
                    <Box
                        // sx={{
                        //     padding: 2,
                        //     backgroundColor: '#f5f5f5',
                        //     height: 'auto',
                        // }}
                    >
                        <p>
                            Explore some of the amazing artwork created by our students and get inspired to create your own masterpieces!
                        </p>
                        {/* <Typography variant="h6" gutterBottom style={{ color: '#088395', fontWeight: 'bold' }}>
                            Explore Our Children's Art
                        </Typography> */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <img src={slidedata1} alt="Art 1" style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <img src={slidedata2} alt="Art 2" style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <img src={slidedata3} alt="Art 3" style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <img src={slidedata4} alt="Art 4" style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <img src={slidedata5} alt="Art 5" style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <img src={slidedata6} alt="Art 6" style={{ width: '100%', height: '250px', objectFit: 'cover', borderRadius: '8px' }} />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </div>
    <Box my={5}>
  <Typography variant="h5" gutterBottom className="header" style={{ color: '#088395', fontWeight: 'bold' }}>
    Drawing Video Tutorials
  </Typography>
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={4}>
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/oBCg0CD-TcQ"
          title="Drawing Tutorial 1"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/PiJkRJgjFBs"
          title="Drawing Tutorial 2"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/mzzvCGfKZR8"
          title="Drawing Tutorial 3"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Grid>
  </Grid>
</Box>
    <Footer /></>
  );
};

export default ArtsPage;