import React, { useState, useEffect } from 'react';
import './Header.css'
import { NavLink } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { IoMdContacts } from "react-icons/io";
import { IoIosInformationCircle } from "react-icons/io";
import logo from '../images/logo1.jpeg';
import { IoSchoolSharp } from "react-icons/io5";
import { IoMdColorPalette } from "react-icons/io";

const TopNav = () => {
    const [sidenavOpen, setSidenavOpen] = useState(false);

    const openSidenav = () => {
        setSidenavOpen(true);
    };

    const closeSidenav = () => {
        setSidenavOpen(false);
    };

    useEffect(() => {
        if (sidenavOpen) {
            document.body.classList.add('sidenav-open');
        } else {
            document.body.classList.remove('sidenav-open');
        }
    }, [sidenavOpen]);

    return (
        <>
            <nav className="topnav">
                <div className="topnav-container">
                    <img src={logo} style={{ width: "3%", height: "3%" }} alt="Logo" />
                    {/* Menu icon for mobile */}
                    <span className="menu-icon" onClick={openSidenav}>
                        &#9776; {/* This is the 'hamburger' icon */}
                    </span>
                    <div className="nav-links">
                        <NavLink to="/" exact activeClassName="active">
                            <FaHome className='icon' /> Home
                        </NavLink>
                        <NavLink to="/Service" activeClassName="active">
                            <GrServices className='icon' /> Services
                        </NavLink>
                        <NavLink to="/internship" activeClassName="active">
                            <IoSchoolSharp className='icon' /> Internship
                        </NavLink>
                        <NavLink to="/about" activeClassName="active">
                            <IoIosInformationCircle className='icon' /> About
                        </NavLink>
                        <NavLink to="/arts" activeClassName="active">
                            <IoMdColorPalette className='icon' /> Arts
                        </NavLink>
                        <NavLink to="/Contact" activeClassName="active">
                            <IoMdContacts className='icon' /> Contact
                        </NavLink>
                    </div>
                </div>
            </nav>

            {/* Sidenav for mobile */}
            <div className="sidenav" style={{ width: sidenavOpen ? '250px' : '0' }}>
                <span className="closebtn" onClick={closeSidenav}>&times;</span>
                <NavLink to="/Home" onClick={closeSidenav} className="sidenav-icons">
                    <FaHome className='icon' /> Home
                </NavLink>
                <NavLink to="/Service" onClick={closeSidenav} className="sidenav-icons">
                    <GrServices className='icon' /> Services
                </NavLink>
                <NavLink to="/internship" onClick={closeSidenav} className="sidenav-icons">
                    <IoSchoolSharp className='icon' /> Internship
                </NavLink>
                <NavLink to="/about" onClick={closeSidenav} className="sidenav-icons">
                    <IoIosInformationCircle className='icon' /> About
                </NavLink>
                <NavLink to="/arts" onClick={closeSidenav} className="sidenav-icons">
                    <IoMdColorPalette className='icon' /> Arts
                </NavLink>
                <NavLink to="/Contact" onClick={closeSidenav} className="sidenav-icons">
                    <IoMdContacts className='icon' /> Contact
                </NavLink>
            </div>
        </>
    );
};

export default TopNav;